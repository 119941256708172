.inline-image-and-paragraph {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 80px 100px;
    padding-bottom: 0px;

    &:nth-child(2n) {
        flex-flow: row-reverse;
    }

    .image {
        width: 40%;
        height: 40%;
        display: flex;
        // align-items: flex-end;

        img {
            max-width: 100%;
        }
    }

    .click-to-zoom {
        text-align: center;
        opacity: 0.7;
        margin-top: 10px;
        font-size: 14px;

        span {
            margin-left: 10px;
        }
    }

    .explanatory-image-enlarged {
        left: 0;
    }

    .paragraph {
        width: 50%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;

        h2 {
            font-size: 40px;
            line-height: 50px;
            font-weight: 400;
            color: #62d6ff;
            width: 100%;

            span {
                font-weight: 600;
                color: #000000;
            }
        }

        h3 {
            font-size: 25px;
            line-height: 30px;
            font-weight: 400;
            color: #62d6ff;
            width: 100%;

            span {
                font-weight: 600;
                color: #000000;
            }
        }

        p,
        a {
            margin-top: 15px;
            font-size: 20px;
            line-height: 30px;
            color: #3C3C3C;
        }

        a.inline-image-text-navigation-link {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-top: 20px;

            span {
                color: #0078D1;
                text-align: left;
                font-weight: 600;
            }

            img {
                margin-left: 5px;
                width: 18px;
                height: 18px;
            }
        }
    }
}

@media screen and (max-width: 1280px) and (min-width: 1024px) {
    .inline-image-and-paragraph {
        padding: 50px;
        padding-bottom: 0px;

        .image {
            width: 30%;
            align-items: center;
        }

        .paragraph {
            width: 65%;

            h2 {
                font-size: 36px;
                line-height: 42px;
            }

            p,
            a {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
}

@media screen and (max-width: 1023px) {
    .inline-image-and-paragraph {
        padding: 30px;
        flex-flow: column;
        padding-bottom: 0px;

        .image {
            display: none;
        }

        .paragraph {
            width: 100% !important;
            align-items: flex-start;
            text-align: left;

            h2 {
                font-size: 30px;
                line-height: 40px;
                text-align: left;
            }

            h3 {
                font-size: 20px;
                text-align: left;
            }

            p,
            a {
                font-size: 16px;
                line-height: 24px;
                text-align: left;
                margin-top: 20px;
            }

            a.inline-image-text-navigation-link {
                margin: auto;
            }
        }
    }
}

@media screen and (max-width: 710px) {
    .inline-image-and-paragraph {
        .paragraph {

            h2,h3,
            p,
            a {
                align-self: flex-start;
                text-align: left;
            }

            a.inline-image-text-navigation-link {
                margin-left: 0;
            }
        }
    }
}