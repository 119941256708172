.mobile-nav{
  width: 2rem;
  height: 2rem;
  display: none;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 10;

  .burger{
      width: 2rem;
      height: 0.25rem;
      border-radius: 10px;
      background-color: black;
      transform-origin: 1px;
      transition: all 0.3 linear;
  }
}

// .mobile-container {
//     max-width: 480px;
//     margin: auto;
//     background-color: #555;
//     height: 500px;
//     color: white;
//     border-radius: 10px;
//   }

.topnav {
  overflow: hidden;
  background-color: blueviolet;
  position: inherit;
  list-style-type: none;
}


.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}

.topnav a.icon {
  background: black;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}

.topnav a:hover {
  background-color: #ddd;
  color: blueviolet;
}

.active {
  background-color: #04AA6D;
  color: white;
}

@media (max-width: 767px){
  .mobile-nav{
      display: flex;
      z-index: 10;
      margin-right: 20px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .mobile-nav{
      display: flex;
      z-index: 10;
      margin-right: 20px;
  }
}
