header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 90px;
    background: transparent;
    background: #FFFFFF;
    // position: sticky;
    // top: 0;
    // z-index: 100;

    // padding-top: 100px;
    &.with-banner {
        padding-top: 100px;
    }

    .menu-full {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        /* background: #FFFFFF; */
        z-index: 1000;

        .left {
            display: flex;
            align-items: center;

            img {
                width: 120px;
            }

            .header-logo-subheader {
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: space-between;

                p {
                    display: none;
                    font-size: 42px;
                    line-height: 52px;
                    margin-left: 40px;
                    text-align: left;
                    border-left: 1px solid #000000;
                    padding: 0 40px;
                    color: #0078D1;
                }
            }
        }

        .right {
            ul {
                display: flex;
                align-items: center;
                list-style-type: none;

                li {
                    margin: 0 20px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #ffffff;
                    cursor: pointer;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    a {
                        font-size: 18px;
                        font-weight: 500;
                        color: #ffffff;
                    }

                    a {
                        font-weight: 600;
                        color: #000000;

                        &:hover {
                            color: #9cd7ef !important;

                        }
                    }

                    span {
                        display: flex;
                        align-items: center;

                        svg {
                            font-size: 14px;
                            margin-left: 10px;
                            margin-top: 2px;
                        }
                    }
                }

                li.dropdown-menu {
                    display: flex;
                    flex-flow: column;
                    position: relative;

                    ul.dropdown {
                        display: none;
                        flex-flow: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        position: absolute;
                        width: max-content;
                        right: -40px;
                        top: 40px;
                        padding: 40px;
                        border-radius: 26px;
                        background: #fff;
                        box-shadow: 0px 10px 20px rgba(60, 60, 60, 0.1);

                        li {
                            margin: 10px 0;
                            text-align: left;
                            font-size: 17px;
                            font-weight: 400;
                            color: rgba(60, 60, 60, 1);

                            a {
                                font-size: 17px;
                                font-weight: 400;
                                color: rgba(60, 60, 60, 1);
                            }
                        }

                        li:first-child {
                            margin: 0 0 10px 0;
                        }

                        li:last-child {
                            margin: 10px 0 0 0;
                        }
                    }

                    ul.dropdown:before {
                        content: "";
                        position: absolute;
                        right: 30px;
                        top: -15px;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 15px 15px 15px;
                        border-color: transparent transparent #ffffff transparent;
                        z-index: 9999;
                    }

                    ul.dropdown.show {
                        display: flex;
                        background: #FFF;
                        z-index: 100;
                    }
                }
            }

            ul.accounts-submenu {
                display: flex;
                flex-flow: column;
                align-items: flex-start;
                padding-left: 20px;

                li {
                    font-size: 15px !important;
                    margin: 10px 0 !important;

                    a {
                        font-size: 15px !important;
                    }
                }
            }

            ul.dark {
                font-weight: 600;
                color: #000000;

                li,
                li a {
                    font-weight: 600;
                    color: #000000;

                    &:hover {
                        color: #9cd7ef !important;

                    }
                }

                li {
                    padding: 10px 20px;
                    margin: 0 15px;
                    color: #000000;

                    &:hover {
                        padding: 10px 20px;
                        background-color: #000000;
                        border-radius: 10px;
                        color: #9cd7ef !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .responsive-nav {
        display: flex;
        flex-direction: column;
    }

    header {
        border-bottom: blueviolet 2px solid;
        padding: 20px 10px;

        .menu-full {
            .left {
                img {
                    width: 100px;
                    padding-left: 20px;
                }
            }

            .right {
                ul {
                    display: flex;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 820px) {
    header {
        border-bottom: blueviolet 2px solid;
        padding: 20px 40px;
    }
}