.listing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;
    padding: 50px 90px;

    .listing-heading {
        padding-bottom: 25px;
        font-size: 46px;
        font-weight: 500;
        line-height: 56px;

        h1 {
            font-size: 46px;
            font-weight: 600;
            line-height: 56px;
            color: #62d6ff;
            background: linear-gradient(to right, #0250c5 0%, #d43f8d 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .listing-types {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 100px;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: max-content;
            margin-top: 50px;
            padding: 15px 45px;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            border-radius: 15px;
            color: #ffffff;
            border: none;
            background-image: linear-gradient(to right, #0250c5 0%, #d43f8d 100%);
            background-size: 200% 100%;
            background-position: right bottom;
            transition: all 0.3s ease;

            &:hover {
                background-position: left bottom;
            }
        }

        .listing-provided {
            position: relative;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            // margin-left: 100px;

            img {

                max-width: 200px;
            }

            h4 {
                margin-top: 30px;
                font-size: 22px;
                line-height: 30px;
                font-weight: 600;
                color: #0078D1;
            }

            p {
                margin-top: 20px;
                font-size: 18px;
                line-height: 24px;
                font-weight: 400;
                color: #3C3C3C;
                text-align: center;
            }
        }
    }
}

.listing-types-padding {
    padding-top: 50px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.listing-image img {
        max-width: 100px;
    }

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide .slider-image {
    display: block;
    width: 50%;
    height: 50%;
    object-fit: cover;
}

@media (max-width: 820px) {
    .listing {
        padding: 50px 0px;
    }
    .services {
        h1 {
            padding-top: 36px;
            font-size: 20px;
        }
    }

    .listing-types-padding {
        padding-top: 0;
    }

    .listing {
        .listing-heading {
            h1 {
                font-size: 30px;
            }
        }

        .listing-types {
            flex-direction: column;
            gap: 0;

            .listing-provided {
                margin-left: 0;

            }
        }
    }

    .image-spacing {
        padding-bottom: 80px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .services {
        h1 {
            font-size: 36px;
        }
    }

    .listing {
        padding-top: 0px;
        padding: 50px 60px;

        .listing-heading {
            h1 {
                font-size: 46px;
            }
        }
    }
}