.voip {
    padding: 30px 0;
    background: #FFFFFF;

    h1 {
        text-align: center;
        font-size: 60px;
        color: #62d6ff;
        margin: 15px 0;

        span {
            color: #000000;
            font-size: 64px;
        }
    }

    .signup-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        padding: 15px 45px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        border-radius: 15px;
        color: #ffffff;
        border: none;
        // background: linear-gradient(to right, #000000 50%, #62d6ff 50%);
        background-size: 200% 100%;
        background-position: right bottom;
        transition: all 0.3s ease;
        margin: auto;
        margin-bottom: 40px;
        background-image: linear-gradient(to right, #0250c5 0%, #d43f8d 100%);

        &:hover {
            background-position: left bottom;
        }
    }
}