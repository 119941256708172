.skip-loop {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 80px 100px;
    border-radius: 0px 133px 0px 0px;

    h2 {
        font-size: 40px;
        line-height: 50px;
        font-weight: 400;
        color: #0078D1;

        span {
            font-weight: 600;
        }
    }

    .inefficiency-contents,
    .skip-loop-contents {
        width: 100%;
        display: flex;
        flex-flow: column;

        .inefficiency-row-top,
        .inefficiency-row-bottom,
        .skip-loop-row {
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: space-between;

            .inefficiency-step {
                width: 25%;
                border-radius: 30px;
                padding: 30px;
                box-sizing: border-box;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    img {
                        height: 80px;
                        max-width: 100px;
                    }

                    p {
                        margin-top: 45px;
                        text-align: center;
                        color: #2E3552;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 28px;
                    }
                }
            }

            .step-arrow,
            .step-arrow.reverse {
                display: flex;
                flex-flow: row;

                img {
                    max-width: 60px;
                }
            }

            .step-arrow.reverse {
                transform: matrix(-1, 0, 0, -1, 0, 0);
            }

        }

        .step-arrow-row {
            display: flex;
            flex-direction: column;
            height: 100px;
            align-items: flex-end;
            justify-content: center;

            .item {
                width: 25%;
                display: flex;
                flex-flow: row;
                align-items: center;
                justify-content: center;

                img {
                    transform: matrix(0, 1, -1, 0, 0, 0);
                }
            }
        }

        .inefficiency-row-top {
            .inefficiency-step:nth-child(1) {
                background-color: #F4F4F4;
            }

            .inefficiency-step:nth-child(3) {
                background-color: #FFEFF1;
            }

            .inefficiency-step:nth-child(5) {
                background-color: #DEF7FF;
            }
        }

        .inefficiency-row-bottom {
            .inefficiency-step:nth-child(1) {
                background-color: #FFEFF1;
            }

            .inefficiency-step:nth-child(3) {
                background-color: #FFF6E6;
            }

            .inefficiency-step:nth-child(5) {
                background-color: #E9F5E9;
            }
        }
    }
}

.skip-loop {
    position: relative;

    .step-block {
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 30px;
        box-shadow: 0 14px 43px rgba(33, 54, 61, 0.15);
        cursor: pointer;
        border-radius: 20px;

        &:hover {
            transform: scale(1.1);
            transition: all 0.2s;
        }

        svg {
            font-size: 50px;
            color: #3C3C3C;
        }

        h4 {
            margin-top: 30px;
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            color: #0078D1;
        }

        p {
            margin-top: 20px;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            color: #3C3C3C;
            text-align: center;
        }
    }

    .section-header-box {
        position: absolute;
        background-color: #62d6ff;
        box-shadow: 0 14px 43px rgb(33 54 61 / 15%);
        top: 40px;
        padding: 15px 53px;
        border-radius: 16px;
        left: -30px;

        p {
            font-size: 20px;
            font-weight: 600;
            color: #FFFFFF;
            margin: 0;
            padding: 0;
        }
    }

    p {
        font-size: 20px;
        line-height: 26px;
        font-weight: 400;
        color: #3C3C3C;
        margin-top: 30px;
        text-align: center;
    }

    button.load-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: max-content;
        margin-top: 40px;
        padding: 15px 45px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        border-radius: 100px;
        color: #ffffff;
        border: none;
        background: transparent linear-gradient(90deg, #3a36c6 0%, #66ceac 100%) 0% 0%;

        img {
            width: 50px;
            display: none;
        }
    }

    button.disabled-button {
        opacity: 0.4;
    }

    .skip-loop-contents {

        .initial {
            display: block;
        }

        .later {
            display: none;
        }

        .skip-loop-row {
            flex-wrap: wrap;
            justify-content: space-around;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 100%;
            grid-column-gap: 60px;
            grid-row-gap: 60px;

            a {
                display: contents;
            }

            .skip-loop-step {
                // width: calc(100% / 3 - 80px);
                border: 1px solid #E2DFDF;
                border-radius: 10px;
                // margin-top: 60px;
                box-sizing: border-box;

                .item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        display: flex;
                        border-top-left-radius: 10px;
                        border-top-right-radius: 10px;
                    }

                    p {
                        // font-size: 18px;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 28px;
                        text-align: left;
                        padding: 30px;
                        margin: 0;

                    }
                }
            }
        }
    }

    .skip-loop-contents {
        .visible-content:last-child {
            margin-top: 60px;
        }
    }

    .partner-section {
        display: flex;
        flex-flow: row !important;
        align-items: center;
        justify-content: center;
        gap: 40px;

        .image-content {
            max-width: 150px;

            img {
                width: 100%;
            }
        }
    }
}

.skip-loop.partner-loop .section-header-box {
    top: 0;
}