@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

body.stop-scroll {
  height: 100vh;
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

ul,
li {
  padding: 0;
  margin: 0;
  /* list-style: none; */
  font-family: 'Montserrat', sans-serif;
}

ol {
  counter-reset: section;
  list-style-type: none;
}

a {
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
}

button {
  outline: none;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

#root {
  /* background-color: #80c6df3b; */
  /* background-image: linear-gradient(to right, #0250c5 0%, #d43f8d 100%); */
}